<template>
  <div class="container">
    <div class="search" style="float:right;margin-bottom:10px;">
      <div>
        <el-button type="primary" size="small" @click="addfeddback">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column label="联系邮箱">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈类别">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈产品类型">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈时间">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈状态">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="info" size="small" @click="info(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        cnsuo: '',
        cocn: '',
      },
      list: [
        {describe: '一个毛栗子'}
      ],
    }
  },
  computed: {},
  watch: {},
  methods: {
    addfeddback () {
    },
    info (){

    },
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
